import Swiper from 'swiper/bundle';

export const initializeSwipers = () => {
    document.addEventListener('DOMContentLoaded', () => {
        new Swiper('.promotion-banner-swiper-container', {
            slidesPerView: 'auto',
            slideToClickedSlide: true,
            freeMode: true,
            spaceBetween: 8,
            autoplay: true
        });

        new Swiper('.promotion-banner-wrapper--container', {
            loop: true,
            autoplay: true,
            speed: 500,
            grabCursor: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            on: {
                init: function () {
                    const totalSlides = this.slides.length;
                    const nextButton = document.getElementById('promotion-front-swiper-next');
                    const prevButton = document.getElementById('promotion-front-swiper-prev');
        
                    if (totalSlides <= 1) {
                        nextButton.style.display = 'none';
                        prevButton.style.display = 'none';
                    } else {
                        nextButton.style.display = '';
                        prevButton.style.display = '';
                    }
                },
            },
        });
    });
};

initializeSwipers();